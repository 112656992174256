import downloadFile from './download-file/download-file';
import logger from '../logger';
import { getITRModelWithPolling } from './model-fetcher/itr-fetcher.service';
import * as configuration from '../constants/configurationValues.constants';
import { EnvironmentParams, Environments } from './../constants';
import { settingsManager } from '../settings-manager';
import { map } from '../api-service/apiMap';
import { getEnvironmentParams, compileUrl } from '../api-service/apiService-helper';
import { logToTimberBI, biMethods } from '../timberLogger';

const { apiMapKeys } = map;

const { NIRI_ASSET_REFFERENCE_TYPE_NUMBER } = EnvironmentParams;

const getModelUrl = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  const apiMapKey = apiMapKeys('GetItrFile') || apiMapKeys('getModelByType');
  const { baseUrl, url } = getEnvironmentParams(apiMapKey);
  const modelUrl = compileUrl({
    baseUrl,
    url,
    queryParams: {
      orderId,
      fromGenericApi,
      type: 'itr',
    },
  });
  return modelUrl;
};

const niriQueryParams = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  return {
    assetReferenceType: NIRI_ASSET_REFFERENCE_TYPE_NUMBER,
    orderId,
    fromGenericApi,
    type: 'niri',
  };
};

const getNiriFilePath = () => {
  const apiMapKey = apiMapKeys('GetEvxFile') || apiMapKeys('getModelByType');
  const { baseUrl, url } = getEnvironmentParams(apiMapKey);
  const niriFilePath = compileUrl({
    baseUrl,
    url,
    queryParams: niriQueryParams(),
  });
  return niriFilePath;
};

const getNiriFile = async (progressCB) => {
  try {
    const apiMapKey = apiMapKeys('GetEvxFile') || apiMapKeys('getModelByType');
    const requestParams = {
      selector: apiMapKey,
      queryParams: niriQueryParams(),
    };
    return await downloadFile({ ...requestParams, fileType: 'niri', options: { credentials: 'include' }, progressCB });
  } catch (error) {
    logger
      .error('No NIRI file path')
      .data({ module: 'eupRequests' })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'eupRequests',
        code: 'No NIRI file path',
        description: error.message,
        severity: 'Fatal - No NIRI file path',
      })
    );
  }
};

const getModel = async (orderId) => {
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  const apiMapKey = apiMapKeys('GetItrFile') || apiMapKeys('getModelByType');
  const requestParams = {
    selector: apiMapKey,
    queryParams: {
      orderId,
      fromGenericApi,
      type: 'itr',
    },
  };
  return await getITRModelWithPolling(Environments.EUP, requestParams);
};

const getItrModel = async () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return await getModel(orderId);
};

const getCompareItrModel = async (orderId) => {
  return await getModel(orderId);
};

export const eupRequests = {
  getNiriFile,
  getItrModel,
  getCompareItrModel,
  getModelUrl,
  getNiriFilePath,
};
