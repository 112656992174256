import { utils } from '../../utils';
import logger from '../../logger';
import { getITRModelWithPolling, fetchNiriFileWithPolling } from '../../api-requests/model-fetcher/itr-fetcher.service';
import * as configuration from '../../constants/configurationValues.constants';
import { settingsManager } from '../../settings-manager';
import { Environments, dentalXraiURLs } from '../../constants/environment.constants';
import { map } from '../../api-service/apiMap';
import { handleRequest } from '../../api-service/apiService-helper';
import { eventBus, globalEventsKeys } from '../../event-bus';

const { apiMapKeys } = map;
const serviceName = 'scanner-requests';
const modelTypes = {
  ITR: 'itr',
  NIRI: 'niri',
};

const getNiriFilePath = () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return rxGuid || orderId;
};

const getModelUrl = () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return rxGuid || orderId;
};

const getNiriFile = async (progressCB) => {
  const niriFilePath = getNiriFilePath();
  if (niriFilePath) {
    const isStandalone = utils.isStandaloneMode();
    const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
    const orderId = settingsManager.getConfigValue(configuration.orderId);

    const requestParams = isStandalone
      ? {
          url: `models/${niriFilePath}.niri`,
        }
      : {
          selector: apiMapKeys('getModelByType'),
          queryParams: {
            type: modelTypes.NIRI,
            ...(rxGuid && { rxGuid }),
            ...(orderId && { orderId }),
          },
        };

    return await fetchNiriFileWithPolling({
      requestParams,
      environment: Environments.SCANNER,
      progressCB,
    });
  } else {
    logger
      .error('No NIRI file path')
      .data({ module: 'scannerRequests' })
      .end();
  }
};

const getModel = async ({ queryParams }) => {
  const { rxGuid, orderId, type } = queryParams;
  const modelUrl = `models/${rxGuid || orderId}.${type}`;
  const isStandalone = utils.isStandaloneMode();

  const requestParams = isStandalone
    ? {
        url: modelUrl,
      }
    : {
        selector: apiMapKeys('getModelByType'),
        queryParams,
      };
  return await getITRModelWithPolling(Environments.SCANNER, requestParams);
};

const getItrModel = async () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return await getModel({
    queryParams: {
      type: modelTypes.ITR,
      ...(rxGuid && { rxGuid }),
      ...(orderId && { orderId }),
    },
  });
};

const getCompareItrModel = async (orderId) => {
  return await getModel({
    queryParams: {
      orderId,
      type: modelTypes.ITR,
    },
  });
};

const getDentalXraiToken = async () => {
  try {
    const res = await handleRequest({
      selector: apiMapKeys('getXraiAuthToken'),
      module: serviceName,
    });
    return await res.json();
  } catch {
    return;
  }
};

const getPatientRadiographs = async () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const getPatientRadiographsURL = dentalXraiURLs.getPatientRadiographsURL.replace('$orderId', orderId);
  const dentalXraiToken = await getDentalXraiToken();
  const dentalXraiHeader = dentalXraiToken && {
    Authorization: dentalXraiToken.token_type + ' ' + dentalXraiToken.access_token,
  };
  let patientRadiographs;
  try {
    const res =
      dentalXraiHeader &&
      (await handleRequest({
        url: getPatientRadiographsURL,
        options: { headers: dentalXraiHeader },
        module: serviceName,
      }));
    const patientRadiographsDtos = await res.json();
    patientRadiographs = {
      timestamp: new Date().getTime(),
      patientRadiographsDtos,
    };
  } catch {
    patientRadiographs = {};
  }
  eventBus.raiseEvent(globalEventsKeys.DENTAL_XRAI_DATA_RECIVED, patientRadiographs);
};

export { getNiriFile, getItrModel, getCompareItrModel, getModelUrl, getNiriFilePath, getPatientRadiographs };
