const addWheelAnimation = (explorer, elementId, timeout = 300) => {
  const circularMenu = document.getElementById(elementId);
  const liElements = circularMenu.querySelectorAll('li');
  circularMenu.style.overflow = 'visible';

  liElements.forEach((element) => {
    const explorerName = element.querySelector('div').attributes.explorer.nodeValue;
    const explorerElementToMove = element.querySelector(`#${explorerName}`);

    element.className = element.className + ` fadeOutAnimation`;
    explorerElementToMove.className = explorerElementToMove.className + ` ${explorerName}Animation`;
    element.style.overflow = 'visible';
  });

  const center = document.getElementById('center');
  const canvas = document.getElementsByTagName('canvas')[0];

  center.remove();
  canvas.style.visibility = 'hidden';

  setTimeout(() => {
    explorer.activateExplorer();
    canvas.style.visibility = 'visible';
  }, timeout);
};

export const wheelAnimation = {
  addWheelAnimation,
};
