import { utils } from '../utils';
import { apiConfig } from './apiConfig';
import { appSettingsManager } from '../app-settings-manager';
import { eventBus, globalEventsKeys } from '../event-bus';

const { isScannerHostEnv } = utils;
const { web3DViewerBffMap, myiteroBffMap, scannerMATApiMap } = apiConfig;
let currentMap = { ...web3DViewerBffMap };

export const map = {
  initApiMap: (isWeb3DToolBFFEnabled) => {
    const isScannerHost = isScannerHostEnv();

    if (isScannerHost) {
      currentMap = scannerMATApiMap;
      appSettingsManager.addSetting('apiMap', scannerMATApiMap);
    } else {
      currentMap = isWeb3DToolBFFEnabled ? web3DViewerBffMap : myiteroBffMap;
      appSettingsManager.addSetting('apiMap', currentMap);

      eventBus.subscribeToEvent(globalEventsKeys.API_MAPPING_CHANGED, ({ isWeb3DToolBFFEnabled }) => {
        currentMap = isWeb3DToolBFFEnabled ? web3DViewerBffMap : myiteroBffMap;
        appSettingsManager.addSetting('apiMap', currentMap);
      });
    }
  },

  apiMap: (selector) => {
    const apiMap = appSettingsManager.getAppSettingsByValue('apiMap');
    return selector ? apiMap[selector] : apiMap;
  },

  apiMapKeys: (key) => {
    const apiMapKeysObj = {};
    Object.keys(currentMap).forEach((key) => {
      apiMapKeysObj[key] = key;
    });
    return key ? apiMapKeysObj[key] : apiMapKeysObj;
  },
};
