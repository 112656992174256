import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { utils } from '@web-3d-tool/shared-logic';
import classNames from 'classnames';
import { StyledPieCenter } from './styled/Center';
import { StyledPieMenu } from './styled/PieMenu';
import MenuSlice from './Slice/MenuSlice';
import Styles from './CircularMenu.module.css';

const CIRCULAR_MENU_ID = 'circular-menu';

const CircularMenu = ({ explorers, wheelAnimation }) => {
  const calculatedValues = useMemo(() => {
    const { calcWindowWidth, calc360HubCircularMenuRadius } = utils;
    const width = calcWindowWidth();
    const radius = calc360HubCircularMenuRadius();
    return { radius: `${radius}px`, centerRadius: `${width * 0.095}px`, fontSize: `${(width * 1.3) / 100}px` };
  }, []);

  const { addWheelAnimation } = wheelAnimation;

  let [currentExplorer, setCurrentExplorer] = useState({});
  let [runAnimation, setRunAnimation] = useState(false);
  let [explorerStyles, setExplorerStyles] = useState({
    explorerIconStyle: Styles.explorerIcon,
    explorerContentStyle: Styles.explorerContent,
    pieMenuStyle: Styles.fadeInAnimation,
  });

  const onClick = async (explorer) => {
    setCurrentExplorer(explorer);
    setExplorerStyles({
      ...explorerStyles,
      pieMenuStyle: classNames(Styles.circularMenuAnimation),
      explorerIconStyle: classNames(Styles.explorerIcon, Styles.explorerIconFadeOutAnimation),
      explorerContentStyle: classNames(Styles.explorerContent, Styles.fadeOutAnimation),
    });
    setRunAnimation(true);
  };

  useEffect(() => {
    if (runAnimation) {
      addWheelAnimation(currentExplorer, CIRCULAR_MENU_ID);
    }
    // eslint-disable-next-line
  }, [runAnimation]);

  return (
    <div style={{ height: '100vh' }}>
      <StyledPieMenu
        startOffsetAngle={35}
        attrs={{ id: CIRCULAR_MENU_ID }}
        radius={calculatedValues.radius}
        centerRadius={calculatedValues.centerRadius}
        centerX="50%"
        centerY="50%"
        Center={StyledPieCenter}
        explorers={explorers}
        className={explorerStyles.pieMenuStyle}
      >
        {explorers.map((explorer) => (
          <MenuSlice
            key={explorer.id}
            explorer={explorer}
            fontSize={calculatedValues.fontSize}
            onSelect={onClick}
            explorerStyles={explorerStyles}
            isActiveExplorer={currentExplorer.id === explorer.id}
          />
        ))}
      </StyledPieMenu>
    </div>
  );
};

CircularMenu.propTypes = {
  /**
   * Array of explorers
   */
  explorers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Explorer id
       */
      id: PropTypes.string.isRequired,
      /**
       * Call back to active explorer
       */
      activateExplorer: PropTypes.func.isRequired,
      /**
       * explorer title
       */
      title: PropTypes.string.isRequired,
      /**
       * Is explorer enabled boolean
       */
      enabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

// CircularMenu.defaultProps = {
//   explorers: []
// };

export default CircularMenu;
