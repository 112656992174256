import queryString from 'query-string';
import UAParser from 'ua-parser-js';
import { get, keys } from 'lodash';
import moment from 'moment';
import { NIRI, IOC, NiriAndIoc } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import * as configuration from '../constants/configurationValues.constants';
import { ScanSystemTypes } from '../constants/scannerSystemTypes.constants';
import { Environments } from '../constants/environment.constants';
import { settingsManager } from '../settings-manager';
import { cacheManager, cacheKeys } from '../cache-manager';
import localStorageKeys from '../constants/localStorageKeys';

const userAgentParser = new UAParser();
const browser = userAgentParser.getBrowser();

const getUrlParam = ({ url, param }) => {
  const urlToSearch = url || window.location.href;
  const arr = urlToSearch.split('?');
  const search = url ? (arr.length === 1 ? '' : arr[1]) : window.location.search;
  const paramsObj = queryString.parse(search);
  const lowerCaseParamObj = keys(paramsObj).reduce((acc, key) => {
    acc[key.toLowerCase()] = paramsObj[key];
    return acc;
  }, {});
  return lowerCaseParamObj[param.toLowerCase()];
};
const getSessionType = () => {
  const SessionType = settingsManager.getConfigValue(configuration.SessionType);
  return SessionType;
};
const getSessionId = () => {
  const SessionId = settingsManager.getConfigValue(configuration.SessionId);
  return SessionId;
};
const getIs360HubEnabled = () => {
  const layout360 = settingsManager.getConfigValue(configuration.layout360);
  return layout360 === 'true';
};

const getBrowser = () => browser;

/**
 * This function is using ua-parser-js as singlethon
 * It supposed to have a rules object which looks like this:
 * {
 *  ie:400,
 *  default:100
 * }
 * and return the value according to the current browser
 * @param {Object} rules
 */
const getValueByBrowser = (rules) => {
  const browserName = browser.name.toLowerCase();
  return get(rules, browserName) || rules.default || null;
};

const getAppVersion = () => process.env.APP_VERSION;

const makeCancelablePromise = (promise) => {
  let hasCanceled = false;
  let hasSettled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
      )
      .finally(() => {
        hasSettled = true;
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      if (!hasSettled) {
        hasCanceled = true;
      }

      return hasCanceled;
    },
  };
};

const getEnv = () => {
  let host = utils.getUrlParam({ param: 'host' });
  return host === 'eup' ? Environments.EUP : Environments.SCANNER;
};

const calcWindowWidth = () =>
  window.innerWidth >= 1480 && window.innerWidth <= 1920 ? window.innerWidth : window.innerWidth > 1920 ? 1920 : 1480;

const calc360HubCircularMenuRadius = () => calcWindowWidth() * 0.205;

const formatDateAndTime = (dateString) => {
  const formattedDateAndTime = moment(dateString).format('D MMM YYYY,hh:mm a');
  const [formattedDate, formattedTime] = formattedDateAndTime.split(',');
  return { formattedDate, formattedTime };
};

const getIsScanOriginLumina = () => {
  const model = cacheManager.get(cacheKeys.MODEL);
  if (model) {
    const { originScanner } = model;
    return originScanner ? originScanner === ScanSystemTypes.Lumina : false;
  }
  return false;
};

const is5DPluginActiveIn360Hub = (sdk) => {
  const is360 = getIs360HubEnabled();
  const activatedPlugins = sdk.getActivatedPlugins();
  const isNiriIndicationOn = [NIRI.id, IOC.id, NiriAndIoc.id].some((id) => activatedPlugins.includes(id));
  return is360 && isNiriIndicationOn;
};

const getOrderId = () => {
  return settingsManager.getConfigValue(configuration.orderId);
};

const getCompanyId = () => {
  return settingsManager.getConfigValue(configuration.companyId);
};

const getDoctorId = () => {
  return settingsManager.getConfigValue(configuration.userId);
};

const getRxGuid = () => {
  return settingsManager.getConfigValue(configuration.rxGuid);
};

const getModelCaseType = () => {
  const isScannerHost = isScannerHostEnv();
  if (isScannerHost) {
    return settingsManager.getConfigValue(configuration.mode);
  } else {
    const { pathname } = window.location;
    const caseType = pathname.toLowerCase().includes('itero') ? 'resto' : 'ortho';
    return caseType;
  }
};

const isStandaloneMode = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isScannerHostEnv() || isEupHostEnv()) return false;
  if (window.self === window.top && isDevelopment) return true;
};

const isScannerHostEnv = () => {
  const host = utils.getEnv();
  const isSettingsManagerInitialized = cacheManager.get(cacheKeys.CONFIGURATION);
  !isSettingsManagerInitialized && settingsManager.init();
  const baseUnitType = settingsManager.getConfigValue(configuration.BaseUnitType);
  return host === Environments.SCANNER && !!baseUnitType;
};

const isEupHostEnv = () => {
  const host = utils.getEnv();
  return host === Environments.EUP;
};

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const promiseResult = async (promise) => {
  try {
    const result = await promise;
    return [undefined, result];
  } catch (error) {
    return [error, undefined];
  }
};

const getLocationParentObject = (sdk) => {
  const is360 = getIs360HubEnabled();
  if (is360) {
    const { currentExplorer } = sdk.getActiveExplorer();
    if (!currentExplorer) {
      return '360 Hub';
    } else {
      return currentExplorer;
    }
  } else {
    return '';
  }
};

const isMac = () => {
  return /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
};

const getLogonasId = () => {
  const { sessionInfo: SESSION_INFO } = localStorageKeys;
  const sessionInfo = JSON.parse(localStorage.getItem(SESSION_INFO));
  if (!sessionInfo) return 0;
  const { loggedInUserId: logonasId } = sessionInfo;
  return logonasId.toString();
};

export const utils = {
  getSessionId,
  getSessionType,
  getUrlParam,
  getIs360HubEnabled,
  getAppVersion,
  getValueByBrowser,
  getBrowser,
  makeCancelablePromise,
  getEnv,
  calcWindowWidth,
  formatDateAndTime,
  getIsScanOriginLumina,
  is5DPluginActiveIn360Hub,
  getOrderId,
  getCompanyId,
  getDoctorId,
  getRxGuid,
  isStandaloneMode,
  isValidUrl,
  promiseResult,
  getLocationParentObject,
  isMac,
  isScannerHostEnv,
  isEupHostEnv,
  calc360HubCircularMenuRadius,
  getModelCaseType,
  getLogonasId,
};
