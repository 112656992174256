import { eventBus, globalEventsKeys, hostEvents } from '../event-bus';
import { OutgoingMessagesKeys } from '../constants';
import { hostCommunicationManager } from '../host-communication';
import { logToTimber, logToTimberBI, biMethods } from '../timberLogger';
import { featureAvaliability } from '../feature-avaliability/feature-avaliability-service';
import { map } from '../api-service/apiMap';
import appSettingsService from '../api-requests/app-settings-service/app-settings-service';
import interceptor from '../interceptor/interceptor';
import logger from '../logger';

const { apiMapKeys } = map;
const appName = 'web-3d-tool';
const localStorageKey = 'sessionInfo';
const serviceName = 'sessionInfo-service';

const sessionInfoService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      eventBus.subscribeToEvent(hostEvents.GET_SESSION_INFO, async (parentSessionInfo) => {
        if (parentSessionInfo && typeof parentSessionInfo === 'object') {
          localStorage.setItem(localStorageKey, JSON.stringify(parentSessionInfo));

          try {
            await appSettingsService.requestSingle({ selector: apiMapKeys('getAllFlagsState') });
            const isSCTEnabled = featureAvaliability.getIsSCTEnabled();
            const isWeb3DToolSCTEnabled = featureAvaliability.getIsWeb3DToolSCTEnabled();
            const isWeb3DToolBFFEnabled = featureAvaliability.getEnableWeb3DToolBFF();
            eventBus.raiseEvent(globalEventsKeys.API_MAPPING_CHANGED, { isWeb3DToolBFFEnabled });
            await interceptor({ isSCTEnabled: isSCTEnabled && isWeb3DToolSCTEnabled });
          } catch (error) {
            logger
              .warn('Failed to get Feature flag from web-3d-tool.bff, trying to get it from myitero.bff instead.')
              .to(['analytics', 'host'])
              .data({ module: serviceName, err: error.message })
              .end();

            eventBus.raiseEvent(globalEventsKeys.API_MAPPING_CHANGED, { isWeb3DToolBFFEnabled: false });
            await appSettingsService.requestSingle({ selector: apiMapKeys('getFeaturesToggleSettings') });
            await interceptor({ isSCTEnabled: false });
          }

          resolve(true);
        } else {
          reject(new Error('Failed to get sessionInfo from MIDC'));
        }
      });
      hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.GET_SESSION_INFO, {
        appName: appName,
        eventName: OutgoingMessagesKeys.GET_SESSION_INFO,
      });
    } catch (error) {
      logger
        .error('Failed to get sessionInfo from MIDC')
        .to(['analytics', 'host'])
        .data({ module: serviceName, err: error.message })
        .end();

      logToTimber({
        timberData: {
          action: `postMessage to parent iframe failed`,
          module: serviceName,
          type: 'object',
          actor: 'System',
          value: {
            errorMessage: error.message,
          },
        },
      });

      logToTimberBI(
        biMethods.errorReportBiLog({
          object: serviceName,
          code: 'Post message to parent iframe failed',
          description: error.message,
          severity: 'Fatal - failed to get sessionInfo from MIDC',
        })
      );

      reject(false);
    }
  });
};
export default sessionInfoService;
