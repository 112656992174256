import { values, set, omit, get, includes } from 'lodash';
import flatten from 'flat';
import * as plugins from '../constants/tools.constants';
import { default as logger } from '../logger';
import { defaultPluginEvents, globalEventsKeys, hostEvents, toolsEvents } from './supportedKeys';

const getPluginEvents = () => {
  const obj = {};

  const addEvents = (pluginId) => {
    obj[pluginId] = {};
    for (const eventKey in defaultPluginEvents) obj[pluginId][eventKey] = `${pluginId}.${eventKey}`;
  };

  for (const pluginKey in plugins) {
    const plugin = plugins[pluginKey];
    plugin && addEvents(plugin.id);
  }

  return obj;
};

const SUPPORTED_KEYS = values(flatten({ ...getPluginEvents(), ...globalEventsKeys, ...toolsEvents, ...hostEvents }));

window.globalSubscriptions = {};
export const eventBus = {
  subscribeToEvent: (key, func) => {
    if (includes(SUPPORTED_KEYS, key)) {
      const id = (new Date().valueOf().toString() + Math.random()).replace('.', '');
      key && (window.globalSubscriptions = set(window.globalSubscriptions, `${key}.${id}`, func));
      return {
        unsubscribe: () => {
          key && (window.globalSubscriptions = omit(window.globalSubscriptions, `${key}.${id}`));
        },
      };
    } else {
      const message = `key is not supported! key: ${key}. Supported keys are: ${SUPPORTED_KEYS.join(', ')}`;
      logger
        .warn(message)
        .data({ module: 'event-bus' })
        .end();
      return null;
    }
  },
  raiseEvent: (messageKey, payload) => {
    let message = `raising '${messageKey}' event`;
    payload && (message = `${message}`);
    const subscriptions = get(window.globalSubscriptions, messageKey);
    values(subscriptions).forEach((func) => func(payload));
  },
};
