const myiteroBffMap = {
  GetItrFile: {
    method: 'GET',
    path: 'mvc/orderfiles/GetItrFile',
  },
  GetEvxFile: {
    method: 'GET',
    path: 'mvc/orderfiles/GetEvxFile',
  },
  IsItrFileExists: {
    method: 'GET',
    path: 'mvc/orderfiles/IsItrFileExists/',
  },
  getFeaturesToggleSettings: {
    method: 'GET',
    path: 'mvc/featuresToggleSettings/getFeaturesToggleSettings',
  },
  getCompanySoftwareOptions: {
    method: 'GET',
    path: 'mvc/settings/getCompanySoftwareOptionIds',
  },
  getXraiAuthToken: {
    method: 'GET',
    path: '',
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'mvc/settings/areScannerSoftwareOptionsAvailable',
  },
};

const web3DViewerBffMap = {
  getModelByType: {
    method: 'GET',
    path: 'api/v1/DownloadFile/GetModelByType',
  },
  IsItrFileExists: {
    method: 'GET',
    path: 'api/v1/DownloadFile/IsItrFileExists',
  },
  getPatientOrdersForComparison: {
    method: 'GET',
    path: 'api/v1/PatientOrders/GetPatientOrdersForComparison',
  },
  getAllFlagsState: {
    method: 'GET',
    path: 'api/v1/Settings/GetAllFlagsState',
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'api/v1/Settings/areScannerSoftwareOptionsAvailable',
  },
};

const scannerMATApiMap = {
  getModelByType: {
    method: 'GET',
    path: 'webviewer/getModelByType',
  },
  getFeaturesToggleSettings: {
    method: 'GET',
    path: 'webviewer/getFeaturesToggleSettings',
  },
  getCompanySoftwareOptions: {
    method: 'GET',
    path: 'webviewer/getCompanySoftwareOptions',
  },
  getPatientOrdersForComparison: {
    method: 'GET',
    path: 'webviewer/getPatientOrdersForComparison',
  },
  getXraiAuthToken: {
    method: 'GET',
    path: 'webviewer/getXraiAuthToken',
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'webviewer/areScannerSoftwareOptionsAvailable',
  },
};

export const apiConfig = {
  myiteroBffMap,
  web3DViewerBffMap,
  scannerMATApiMap,
};
